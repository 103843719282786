<header class="page__header header">
    <div class="header__brand brand">
        <img src="../assets/images/metanous.svg"
             alt="Metanous"
             class="brand__image">

        <span class="brand__label">
            Metanous
            <span>Demo</span>
        </span>
    </div>

    <nav class="header__nav">
        <nav class="nav--primary">
            <ul>
                <li>
                    <a title="Live view"
                       [ngClass]="{'is-active': selectedItem == liveItem}"
                       (click)="changeSelectedItem(liveItem)">
                        <svg width="32"
                             height="28"
                             viewBox="0 0 32 28"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                  d="M3 1.5V23.5C3 24.375 3.625 25 4.5 25H30.5C31.3125 25 32 25.6875 32 26.5C32 27.375 31.3125 28 30.5 28H4.5C2 28 0 26 0 23.5V1.5C0 0.6875 0.625 0 1.5 0C2.3125 0 3 0.6875 3 1.5ZM29.5625 8.5625L21 17.0625C20.4375 17.6875 19.5 17.6875 18.9375 17.0625L14 12.125L9.0625 17.0625C8.4375 17.6875 7.5 17.6875 6.9375 17.0625C6.3125 16.5 6.3125 15.5625 6.9375 15L12.9375 9C13.1875 8.6875 13.5625 8.5625 14 8.5625C14.375 8.5625 14.75 8.6875 15.0625 9L20 13.9375L27.4375 6.5C28 5.875 28.9375 5.875 29.5625 6.5C30.125 7.0625 30.125 8 29.5625 8.5625Z" />
                        </svg>
                        <span>
                            Live view
                        </span>
                    </a>
                </li>

                <li *ngIf="isAdmin">
                    <a title="Controls"
                       [ngClass]="{'is-active': selectedItem == controlsItem}"
                       (click)="changeSelectedItem(controlsItem)">
                        <svg width="32"
                             height="30"
                             viewBox="0 0 32 30"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                  d="M0 25C0 24.1875 0.625 23.5 1.5 23.5H5.1875C5.8125 21.5 7.75 20 10 20C12.1875 20 14.125 21.5 14.75 23.5H30.5C31.3125 23.5 32 24.1875 32 25C32 25.875 31.3125 26.5 30.5 26.5H14.75C14.125 28.5625 12.1875 30 10 30C7.75 30 5.8125 28.5625 5.1875 26.5H1.5C0.625 26.5 0 25.875 0 25ZM8 25C8 26.125 8.875 27 10 27C11.0625 27 12 26.125 12 25C12 23.9375 11.0625 23 10 23C8.875 23 8 23.9375 8 25ZM20 15C20 16.125 20.875 17 22 17C23.0625 17 24 16.125 24 15C24 13.9375 23.0625 13 22 13C20.875 13 20 13.9375 20 15ZM22 10C24.1875 10 26.125 11.5 26.75 13.5H30.5C31.3125 13.5 32 14.1875 32 15C32 15.875 31.3125 16.5 30.5 16.5H26.75C26.125 18.5625 24.1875 20 22 20C19.75 20 17.8125 18.5625 17.1875 16.5H1.5C0.625 16.5 0 15.875 0 15C0 14.1875 0.625 13.5 1.5 13.5H17.1875C17.8125 11.5 19.75 10 22 10ZM12 7C13.0625 7 14 6.125 14 5C14 3.9375 13.0625 3 12 3C10.875 3 10 3.9375 10 5C10 6.125 10.875 7 12 7ZM16.75 3.5H30.5C31.3125 3.5 32 4.1875 32 5C32 5.875 31.3125 6.5 30.5 6.5H16.75C16.125 8.5625 14.1875 10 12 10C9.75 10 7.8125 8.5625 7.1875 6.5H1.5C0.625 6.5 0 5.875 0 5C0 4.1875 0.625 3.5 1.5 3.5H7.1875C7.8125 1.5 9.75 0 12 0C14.1875 0 16.125 1.5 16.75 3.5Z" />
                        </svg>
                        <span>
                            Controls
                        </span>
                    </a>
                </li>

                <li>
                    <a title="Reports"
                       [ngClass]="{'is-active': selectedItem == reportsItem}"
                       (click)="changeSelectedItem(reportsItem)">
                        <svg width="32"
                             height="28"
                             viewBox="0 0 32 28"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                  d="M12 3V8.5H29V4C29 3.5 28.5 3 28 3H12ZM9 3H4C3.4375 3 3 3.5 3 4V8.5H9V3ZM3 11.5V16.5H9V11.5H3ZM3 19.5V24C3 24.5625 3.4375 25 4 25H9V19.5H3ZM12 25H28C28.5 25 29 24.5625 29 24V19.5H12V25ZM29 16.5V11.5H12V16.5H29ZM0 4C0 1.8125 1.75 0 4 0H28C30.1875 0 32 1.8125 32 4V24C32 26.25 30.1875 28 28 28H4C1.75 28 0 26.25 0 24V4Z" />
                        </svg>
                        <span>
                            Reports
                        </span>
                    </a>
                </li>

                <li>
                    <a title="Grafana"
                       [ngClass]="{'is-active': selectedItem == grafanaItem}"
                       (click)="changeSelectedItem(grafanaItem)">
                        <svg width="33"
                             height="32"
                             viewBox="0 0 33 32"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                  d="M17 3C12.3125 3 8.0625 5.5 5.6875 9.5C3.375 13.5625 3.375 18.5 5.6875 22.5C8.0625 26.5625 12.3125 29 17 29C21.625 29 25.875 26.5625 28.25 22.5C30.5625 18.5 30.5625 13.5625 28.25 9.5C25.875 5.5 21.625 3 17 3ZM17 32C11.25 32 6 29 3.125 24C0.25 19.0625 0.25 13 3.125 8C6 3.0625 11.25 0 17 0C22.6875 0 27.9375 3.0625 30.8125 8C33.6875 13 33.6875 19.0625 30.8125 24C27.9375 29 22.6875 32 17 32ZM20.5 22C20.5 23.9375 18.875 25.5 17 25.5C15.0625 25.5 13.5 23.9375 13.5 22C13.5 20.625 14.3125 19.4375 15.5 18.875V7.5C15.5 6.6875 16.125 6 17 6C17.8125 6 18.5 6.6875 18.5 7.5V18.875C19.625 19.4375 20.5 20.625 20.5 22ZM11 12C9.875 12 9 11.125 9 10C9 8.9375 9.875 8 11 8C12.0625 8 13 8.9375 13 10C13 11.125 12.0625 12 11 12ZM10 16C10 17.125 9.0625 18 8 18C6.875 18 6 17.125 6 16C6 14.9375 6.875 14 8 14C9.0625 14 10 14.9375 10 16ZM26 18C24.875 18 24 17.125 24 16C24 14.9375 24.875 14 26 14C27.0625 14 28 14.9375 28 16C28 17.125 27.0625 18 26 18ZM25 10C25 11.125 24.0625 12 23 12C21.875 12 21 11.125 21 10C21 8.9375 21.875 8 23 8C24.0625 8 25 8.9375 25 10Z" />
                        </svg>
                        <span>
                            Grafana
                        </span>
                    </a>
                </li>
            </ul>
        </nav>
    </nav>
    <div class="header__status">
        <div class="status">
            <span [ngStyle]="{'background-color': deviceOffline ? '#ff0000' : '#00ff00'}"
                  class="status__symbol"></span>
            <p-button #loadingbutton
                      [label]="this.onoff ? 'On' : 'Off'"
                      [loading]="loading"
                      styleClass="button--secondary"
                      (onClick)="handleToggleButton()"
                      [disabled]="!isAdmin"></p-button>
        </div>
    </div>
</header>